<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="权益名称">
              <el-input v-model="searchFrom.organizationName" :clearable="true" placeholder="请输入产品名称"></el-input>
            </el-form-item>
            <el-form-item label="归属类别">
              <el-select clearable v-model="searchFrom.organizationCode" placeholder="请选择产品列表">
                <el-option label="全部" value=""></el-option>
                <el-option label="家庭" value="JT"></el-option>
                <el-option label="精英" value="JY"></el-option>
                <el-option label="个人" value="GR"></el-option>
                <el-option label="SVIP" value="SVIP"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间:">
              <el-date-picker
                clearable
                type="date"
                :picker-options="pickerOptionsStart"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                placeholder="选择日期"
                v-model="searchFrom.date1"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
              <el-date-picker
                clearable
                type="date"
                :picker-options="pickerOptionsEnd"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                placeholder="选择日期"
                v-model="searchFrom.date2"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="归属机构">
              <el-select clearable v-model="searchFrom.jigou" placeholder="请选择归属机构">
                <el-option label="全部 " value=""></el-option>
                <el-option
                  v-for="item in companyList"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch" @click="getAllTeamRights(1)">查询</el-button>
              <el-button icon="el-icon-setting" type="primary" plain @click="updateTeamRights">修改</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div>
        <el-button icon="el-icon-plus" type="primary" plain @click="add">添加</el-button>
        <el-button icon="el-icon-delete" type="danger" plain @click="handleDelete">删除</el-button>
      </div>
      <div class="table-block">
        <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
        <el-table
          :data="tableData"
          ref="multipleTable"
          @row-click="handleClickTableRow"
          v-loading="loading"
          :highlight-current-row="true"
          :header-cell-style="{ 'text-align': 'center', background: '#EEF1FC', color: '#656565' }"
          :cell-style="{ 'text-align': 'center' }"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="identityName" label="权益名称"> </el-table-column>
          <el-table-column prop="identityDictionaryName" label="归属类别"> </el-table-column>
          <el-table-column prop="rightName" label="产品权益"> </el-table-column>
          <el-table-column prop="companyName" label="归属机构"> </el-table-column>
          <el-table-column prop="state" width="60" label="状态">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.identityStuts"
                active-color="#5479FF"
                inactive-color="#D5D5D5"
                active-value="1"
                @change="changeStatus(scope.row)"
                inactive-value="-1"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" width="200" label="添加时间"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click="handleLook(scope.row)" type="text" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
        >
        </el-pagination>
        <teamConfigDialog v-if="showEdit" :lookFor="lookFor" :info="info" @closeEdit="closeEdit"></teamConfigDialog>
      </div>
    </el-card>
  </div>
</template>
<script>
import { getAllInstitutionsInfo, postRequest } from 'src/api/index'
import { formatTime } from 'src/utils/utils'
import teamConfigDialog from 'src/views/productManagement/component/teamConfigDialog'
export default {
  name: 'oteamConfig',
  data() {
    return {
      searchFrom: {
        organizationName: '',
        organizationCode: '',
        date1: '',
        date2: '',
        jigou: '',
      },
      tableData: [],
      multipleSelection: [],
      disabledSearch: false,
      showEdit: false,
      companyList: [],
      info: {},
      rightsType: [],
      lookFor: '',
      lineInfo: null,
      loading: true,
      pageNo: 1,
      pageSize: 10,
      total: null,
      pickerOptionsStart: {
        disabledDate: time => {
          if (this.searchFrom.date2) {
            return time.getTime() > new Date(this.searchFrom.date2).getTime()
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          if (this.searchFrom.date1) {
            return time.getTime() < new Date(this.searchFrom.date1).getTime() - 86400000
          }
        },
      },
    }
  },
  components: { teamConfigDialog },
  watch: {},
  computed: {},
  mounted() {
    // 查询机构
    postRequest('/company/queryCompany', {}).then(res => {
      console.log(res, '查询所有机构列表')
      this.companyList = res
    })
    this.getAllTeamRights()
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '06' }).then(res => {
      console.log(res, '权益类别')
      this.rightsType = res.slice(0,4)
    })
  },
  methods: {
    add() {
      this.showEdit = true
      this.info = ''
      this.lookFor = false
    },
    closeEdit() {
      this.showEdit = false
      this.getAllTeamRights()
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getAllTeamRights()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.pageNo = val
      this.getAllTeamRights()
    },
    rowClick(row) {
      console.log(row)
      this.lineInfo = row
    },
    handleClick() {
      console.log('查看')
      // this.showEdit = true
    },
    changeStatus(val) {
      let data = {
        identityId: val.identityId,
        identityStuts: '',
      }
      if (val.identityStuts == '1') {
        data.identityStuts = '-1'
      } else if (val.identityStuts == '-1') {
        data.identityStuts = '1'
      }
      postRequest('/identity/update/', val).then(res => {
        console.log(res)
        this.$message({
          message: '修改状态成功',
          type: 'success',
        })
        // this.getAllTeamRights()
      })
    },
    // 查询所有团队权益
    getAllTeamRights(no) {
      this.loading = true
      this.disabledSearch = true
      let data = {
        identityName: this.searchFrom.organizationName ? this.searchFrom.organizationName : '',
        identityDictionaryCode: this.searchFrom.organizationCode ? this.searchFrom.organizationCode : '',
        createTime: this.searchFrom.date1 ? this.searchFrom.date1 + ' 00:00:00' : '',
        updateTime: this.searchFrom.date2 ? this.searchFrom.date2 + ' 23:59:59' : '',
        companyId: this.searchFrom.jigou ? this.searchFrom.jigou : '',
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize
      }
      postRequest('/identity/queryIdentityPage/', data)
        .then(res => {
          this.disabledSearch = false
          console.log(res, '查询所有团队权益')
          this.loading = false
          this.tableData = res.data
          this.total = res.count
          res.data.map(item => {
            if (item.identityDictionaryCode == 'JY') {
              item.identityDictionaryName = '精英'
            } else if (item.identityDictionaryCode == 'JT') {
              item.identityDictionaryName = '家庭'
            } else if (item.identityDictionaryCode == 'SVIP') {
              item.identityDictionaryName = 'SVIP'
            } else if (item.identityDictionaryCode == 'GR') {
              item.identityDictionaryName = '个人'
            } else if (item.identityDictionaryCode == 'PT') {
              item.identityDictionaryName = '普通'
            }
          })
        })
        .catch(err => {
          this.loading = false
          this.disabledSearch = false
        })
    },
    handleLook(val) {
      this.info = val
      this.lookFor = true
      this.showEdit = true
    },
    // 删除
    handleDelete() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: '请选择一项进行删除',
          type: 'warning',
        })
      }
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          // this.multipleSelection.map(item => {
          //   let data = {
          //     identityId: item.identityId
          //   }
          //   postRequest('/identity/delete/', data).then(res=>{
          //     this.$message({
          //       type: 'success',
          //       message: '删除成功!'
          //     });
          //     this.getAllTeamRights()
          //   })
          // })
          postRequest('/identity/delete/', this.multipleSelection).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getAllTeamRights()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    //  修改
    updateTeamRights() {
      if (this.lineInfo) {
        this.info = this.lineInfo
        this.showEdit = true
        this.lookFor = false
      }
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: '请选择修改项',
          type: 'warning',
        })
        this.showEdit = false
      } else if (this.multipleSelection.length > 1) {
        this.$message({
          message: '请选择一条修改项',
          type: 'warning',
        })
        this.showEdit = false
      } else {
        this.info = this.multipleSelection[0]
        this.showEdit = true
        this.lookFor = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
</style>
