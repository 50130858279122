<template>
  <el-dialog :close-on-click-modal="false" :show-close="false" :visible.sync="dialogVisible" width="60%" @close="handleClose">
    <el-card>
      <p class="title">{{title}}</p>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-col :span="12">
          <el-form-item label="权益名称:" prop="identityName">
            <el-input  maxlength="10" placeholder="请输入权益名称" :disabled="lookFor" show-word-limit v-model="form.identityName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="权益类别:" prop="identityDictionaryCode">
            <el-select v-model="form.identityDictionaryCode" @change="changeSelect" :disabled="lookFor" placeholder="请选择权益类别">
              <el-option label="家庭" value="JT"></el-option>
              <el-option label="精英" value="JY"></el-option>
              <el-option label="个人" value="GR"></el-option>
              <el-option label="SVIP" value="SVIP"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态:" prop="identityStuts">
            <el-select v-model="form.identityStuts" :disabled="lookFor" placeholder="请选择产品状态">
              <el-option label="上架" value="1"></el-option>
              <el-option label="下架" value="-1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="归属机构:" prop="companyId">
            <el-select @change="chanegCompany" v-model="form.companyId" :disabled="lookFor" placeholder="请选择归属机构">
              <el-option
                v-for="item in companyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="起止日期:">
            <el-date-picker
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.value1"
              :disabled="lookFor"
              @input="changeDate123"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="开始时间">
            <el-date-picker
              @change="changeStartDate"
              :disabled="lookFor"
              v-model="form.startTime"
              value-format="yyyy-MM-dd 00:00:00"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束时间">
            <el-date-picker
              v-model="form.endTime"
              :disabled="lookFor"
              value-format="yyyy-MM-dd 23:59:59"
              type="date"
              :picker-options="expireTimeOption"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col> -->
        <el-col :span="24">
          <el-form-item label="权益介绍:" prop="identityRightInfo">
            <el-input type="textarea" placeholder="请输入权益介绍" :disabled="lookFor" v-model="form.identityRightInfo"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <div id="addMore">
            <el-form-item label="特权:">
              <div class="inner">
                <div style="height: 51px;"></div>
              </div>
              <div class="inner">
                <div v-for="(Aitem, index) in form.identityRigthVos" id="add" :key="index">
                  <el-form-item label-width="70px" label="权益名称">
                    <el-select v-model="Aitem.rightId"  :disabled="lookFor" placeholder="请选择产品权益">
                      <el-option v-for="Bitem in rightsList" :key="Bitem.dictionaryId" :label="Bitem.dictionaryName" :value="Bitem.dictionaryId"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item  label-width="60px" label="配额">
                    <el-input placeholder="请输入产品权益配额" :disabled="lookFor" @mousewheel.native.prevent @keyup.native="prevent($event)" type="number" v-model="Aitem.quota"></el-input>
                  </el-form-item>
                    <span @click="deleteLine(index)" v-if="!lookFor" class="delButton">删除</span>
                </div>
              </div>
              <el-button @click="addNew" class="add" v-if="!lookFor" icon="el-icon-plus">新增团体权益</el-button>
              <div class="inner-1">
                <el-form-item label="团体权益图标:" prop="identityStuts1" label-width="100px">
                  <el-upload
                    :class="imageUrl ? '' : 'avatar-uploader'"
                    :action="action"
                    :show-file-list="false"
                    :disabled="lookFor"
                    :on-success="handleAvatarSuccess">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </div>
            </el-form-item>
            <!-- <span class="add" @click="addNew">添加</span> -->
          </div>
        </el-col>
      </el-form>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit('form')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAllInstitutionsInfo, postRequest } from 'src/api/index'
import {imgaction} from 'src/utils/cities'
export default {
  data() {
    return {
      dialogVisible: true,
      title: '新增团体权益',
      companyList: [],
      rightsList: [],
      rightsType: [],
      imageUrl: '',
      isDisabled: false,
      action: imgaction,
      rules: {
        identityName: [
          {required: true, message: '请输入权益名称', trigger: 'blur'}
        ],
        identityDictionaryCode: [
          {required: true, message: '请选择权益类别', trigger: 'change'}
        ],
        identityStuts: [
          {required: true, message: '请选择状态', trigger: 'change'}
        ],
        identityStuts1: [
          {required: false, message: '请选择权益图标', trigger: 'change'}
        ],
        companyId: [
          {required: true, message: '请选择归属机构', trigger: 'change'}
        ],
        startTime: [
          {required: true, message: '请选择开始时间', trigger: 'change'}
        ],
        endTime: [
          {required: true, message: '请选择结束时间', trigger: 'change'}
        ],
        identityRightInfo: [
          {required: false, message: '请输入权益介绍', trigger: 'blur'}
        ],
      },
      expireTimeOption: {
        disabledDate:(date) => {
          return date.getTime() < new Date(this.form.startTime);
        }
      },
      form: {
        identityName: '',
        identityDictionaryCode: '',
        identityRightInfo: '',
        identityStuts: '',
        companyCode: '',
        startTime: '',
        endTime: '',
        identityRigthVos: [
          {
            quota: '',
            rightId: '',
          },
        ],
      },
      // pickerOptionsStart: {
      //   disabledDate: time => {
      //     if (this.form.endTime) {
      //       return time.getTime() > new Date(this.form.endTime).getTime()
      //     }
      //   }
      // },
      // pickerOptionsEnd: {
      //   disabledDate: time => {
      //     if (this.form.startTime) {
      //       return time.getTime() < new Date(this.form.startTime).getTime() - 86400000
      //     }
      //   }
      // },
    }
  },
  props: ['info', 'lookFor'],
  computed: {
    isChange () {
      return this.lookFor || this.isDisabled
    },
  },
  mounted() {
    if (this.info != '') {
      postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '05'}).then(res => {
        console.log(res, '权益')
        this.rightsList = res
      })
      this.isDisabled = true
      this.info.value1 = [this.info.startTime ? this.info.startTime : '', this.info.endTime ? this.info.endTime : '']
      this.imageUrl = this.info.headUrl
      this.info.companyId = Number(this.info.companyId)
      // if (this.info.identityRigthVos.length > 0) {
      // } else {
      //   let a = {
      //   quota: '',
      //   rightId: '',
      //   identityId: this.info.identityId
      // }
      //   this.info.identityRigthVos.push(a)
      // }
      if (this.lookFor) {
        this.title = '查看'
      } else {
        this.title = '修改团体权益'
      }
      this.form = this.info
    }
    postRequest('/company/queryCompany', {}).then(res => {
      this.companyList = res
    })
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '05', stuts: 1 }).then(res => {
      console.log(res, '权益')
      this.rightsList = res
    })
    
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '06'}).then(res => {
      console.log(res, '权益类别')
      this.rightsType = res.slice(0,4)
    })
  },
  methods: {
    handleClose() {
      this.$emit('closeEdit')
    },
    changeStartDate () {
      this.$forceUpdate()
      this.form.endTime = ''
    },
    prevent(e){
      var keynum = window.event ? e.keyCode : e.which;   //获取键盘码
        if (keynum ==189|| keynum==190||keynum == 109 ||keynum == 110 ) {
            this.$message.warning('禁止输入小数以及负数')
            e.target.value = ''
        }
    },
    deleteLine(i){
      this.form.identityRigthVos.splice(i, 1)
    },
    chanegCompany(val){
      this.companyList.map(item=>{
        if (item.companyId == val) {
          this.form.companyName = item.companyName
        }
      })
    },
    handleAvatarSuccess(res, file) {
      console.log(res, 'res', file, 'file')
      this.form.headUrl = res.data
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    addNew() {
      console.log(123)
      let a = {
        quota: '',
        rightId: '',
        identityId: this.info.identityId
      }
      this.form.identityRigthVos.push(a)
    },
    changeDate123(val){
      console.log(123)
      this.$forceUpdate()
      console.log(val)
      this.form.startTime = val[0]
      this.form.endTime = val[1]
    },
    sureCommit(form) {
      if (this.lookFor) {
        this.handleClose()
      } else {
        this.$refs[form].validate((valid) => {
          if (valid) {
            // this.form.identityRigthVos.map((item, index)=> {
            //   if (item.rightId == '' || item.quota == '') {
            //     this.form.identityRigthVos.splice(index, 1)
            //   }
            // })
            for (let i = this.form.identityRigthVos.length -1; i >=0; i--) {
                if (this.form.identityRigthVos[i].rightId == '' || this.form.identityRigthVos[i].quota == '') {
                  this.form.identityRigthVos.splice(i, 1)
                }
              }
            if (this.info != '') {
              postRequest('/identity/update/', this.form).then(res => {
                console.log(res)
                this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                this.handleClose()
              })
              console.log('update')
            } else {
              postRequest('/identity/addSave/', this.form).then(res => {
                console.log(res)
                this.$message({
                  message: '新增成功',
                  type: 'success',
                })
                this.handleClose()
              })
              console.log('add')
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
      
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;
}
#addMore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-form-item {
    width: 100%;
  }
  .add {
    width: 100%;
    display: inline-block;
    font-size: 14px;
    text-align: center;
    background: #EFF2FA;
    color: #5479FF;
    margin-bottom: 20px;
  }
  .inner-1 {
    margin-top: 20px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader {
  width: 146px;
  border: 1px dashed #5479FF !important;
  border-radius: 5px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 146px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 146px;
  height: 146px;
  display: block;
}
img {
  width: 100%;
}
.el-textarea {
  height: 100%;
}
.el-textarea__inner {
  height: 178px !important;
}
.addAndReduce {
  font-size: 16px;
  :last-child{
    margin-left: 10px;
  }
}
.delButton {
  display: inline-block;
  width: 70px;
  text-align: center;
  font-size: 14px;
  color: #E95A52;
  margin-left: 19px;
}
.el-range-editor {
  width: 100%;
}
.title {
  color: #444444;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 0;
  font-family: PingFang SC;
  font-weight: 400;
}
#add {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.el-card {
  max-height: 772px;
  overflow-x: hidden !important;
  margin-top: -30px;
}
.el-date-editor {
  width: 100%;
}
</style>
